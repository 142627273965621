import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isAuthenticatedSelector } from "../../components/Login/selectors";

export default function Home(): React.ReactNode {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    {
      if (isAuthenticated) {
        navigate("/app/compare?view=track");
      } else {
        navigate("/auth/login");
      }
    }
  }, []);

  return null;
}
